
import Vue from "vue"

import Expander from "@/components/ExpandableSection.vue"

const VALID = "✓"
const INVALID = "✖"

export default Vue.extend({
  name: "CharacterCreatorCard",
  components: {
    Expander,
  },
  props: {
    title: String,
    subtitle: String,
    saveStateId: String,
    fullWidth: Boolean,
    footer: String,
    valid: {
      type: Boolean,
      default: false,
    },
    noSign: {
      type: Boolean,
      default: false,
    },
    defaultOpen: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sign(): string {
      if (this.noSign) return ""
      return this.valid ? VALID : INVALID
    },
  },
})
